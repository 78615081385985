import React, { useEffect, useState } from 'react'
import { toast, Bounce } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { faStar, faShareSquare } from '@fortawesome/free-regular-svg-icons'
import { faWhatsapp, faFacebook, faPinterest, faTwitter } from '@fortawesome/free-brands-svg-icons'

import colors from '../../../Theme/colors'

import { CardWrapper, CardHeader, Img, Desc, CardBody, Title, CardFooter, Info, InfoIcon, CardShare } from './style'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { AddFavorito, GetAssociadoById } from '../../../Service/ApiService'

const CardInline = ({ participanteId, onClick }) => {
  const token = useSelector((state) => state.TokenApp)
  const tokenType = useSelector((state) => state.TokenType)
  const [favorite, setFavorite] = useState(false)
  const [share, setShare] = useState(false)
  const [values, setValues] = useState()

  useEffect(() => {
    async function loadData() {
      if (participanteId) setValues(await GetAssociadoById(token, participanteId))
    }

    loadData()
  }, [participanteId])

  async function onFavorito(ev) {
    if (tokenType !== 'Cli') {
      window.location.href = '/Login?f=' + participanteId
    } else {
      favorito()
    }
  }

  async function favorito() {
    var result = await AddFavorito(token, participanteId)
    if (result.resultado) {
      setFavorite(!favorite)
    } else {
      toast(result.message, {
        type: 'warning',
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'top-right',
      })
    }
  }

  const justNumbers = (text) => {
    var numbers = '55' + text.replace(/[^0-9]/g,'');
    return parseInt(numbers);
  }

  return (
    <CardWrapper>
      {values && (
        <Fragment>
          <CardHeader onClick={() => onClick(values)}>
            <Img src={values.urlLogo}></Img>
          </CardHeader>
          <CardBody onClick={() => onClick(values)}>
            <Title>{values.nome}</Title>
            <Desc>{values.descricao}</Desc>
          </CardBody>
          <CardFooter>
            <Info>
              {values.endereco !== ' - ' && (
                <Fragment>
                  <InfoIcon className="icon">
                    <FontAwesomeIcon width={64} icon={faMapMarkerAlt} />
                  </InfoIcon>
                  <p>{values.endereco}</p>
                </Fragment>
              )}
            </Info>
            <Info>
              {values.urlSite && (
                <Fragment>
                  <InfoIcon className="icon">
                    <FontAwesomeIcon width={64} icon={faGlobe} />
                  </InfoIcon>
                  <p>
                    <a href={`${values.urlSite}`} target="_blank" rel="noreferrer">
                      {values.urlSite}
                    </a>
                  </p>
                </Fragment>
              )}
            </Info>

            <Info>
              {values.numWhatsApp !== '' && (
                <Fragment>
                  <InfoIcon className="icon">
                    <FontAwesomeIcon width={64} icon={faWhatsapp} />
                  </InfoIcon>
                  <p>
                    <a href={`https://api.whatsapp.com/send?phone=${justNumbers(values.numWhatsApp)}&text=Olá, estava navegando no Guia da Construção e gostaria de mais informações`} target="_blank" rel="noreferrer">
                      {values.numWhatsApp}
                    </a>
                  </p>
                </Fragment>
              )}
            </Info>
            <Info>
              {values.telefone !== '' && (
                <Fragment>
                  <InfoIcon className="icon">
                    <FontAwesomeIcon width={64} icon={faPhoneAlt} />
                  </InfoIcon>
                  <p>{values.telefone}</p>
                </Fragment>
              )}
            </Info>
          </CardFooter>
          <CardShare>
            <div>
              <FontAwesomeIcon icon={faStar} color={favorite ? colors.YELLOW : ''} onClick={() => onFavorito} />
              <FontAwesomeIcon icon={faShareSquare} onClick={() => setShare(!share)} />
            </div>
            {
              {
                true: (
                  <div className="btn">
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon size="lg" icon={faFacebook} />
                    </a>
                    <a
                      href={`https://twitter.com/intent/tweet?text=${values.nome}&url=${window.location.href}&related=`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon size="lg" icon={faTwitter} />
                    </a>
                    <a
                      href={`http://pinterest.com/pin/create/button/?url=${window.location.href}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon wsize="lg" icon={faPinterest} />
                    </a>
                  </div>
                ),
              }[share]
            }
          </CardShare>
        </Fragment>
      )}
    </CardWrapper>
  )
}

export default CardInline
