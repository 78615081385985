import styled from 'styled-components'
import colors from '../../Theme/colors'
import { styling, sizing } from '../../Theme/fonts'
import imagem from '../../Assets/Images/formsearch.jpg'
import Select from 'react-select'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: ${styling.NORMAL};

  margin-top: 5px;
  background-image: url(${imagem});
  background-size: cover;
  padding-top: 15px;
`
export const FormWrapper = styled.div`
  background-color: rgba(248, 148, 6, 0.7);
  margin: 20px 5%;
  width: calc(90% -20px);
  padding: 30px 10px;
  border-radius: 10px;

  @media (max-width: 688px) {
    display: flex;
    flex-direction: column;
  }
`
export const FormHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  margin: 0 auto;
  margin-top: -15px;
  height: 50%;

  a {
    color: ${colors.WHITE};
    font-size: ${sizing.SMALLER}px;
  }
`
export const TitleMain = styled.h1`
  margin: 0 5%;
  width: 30%;
  font-weight: 400;

  @media (max-width: 688px) {
    /* margin: 0;
        padding: 0 auto;
        width: 100%;
        text-align: center; */
    display: none;
  }
`
export const Title = styled.h3`
  color: ${colors.WHITE};
  letter-spacing: 2px;
  font-size: ${sizing.LARGE}px;
`

export const FormBody = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 5px;
  align-items: center;

  #produto {
    width: 20%;
  }
  #estado {
    width: 15%;
  }
  #cidade {
    width: 20%;
  }

  @media (max-width: 688px) {
    display: flex;
    flex-direction: column;

    #produto,
    #estado,
    #cidade {
      width: 100%;
      margin: 3px 0;
    }
  }
`

export const Combo = styled(Select)`
  border: none;
  padding: 3px;
  .select__control {
    border-radius: 15px;

    :focus {
      outline: none;
    }
  }
`
export const Input = styled.input`
  width: 30%;
  border-radius: 15px;
  height: 38px;
  border: none;
  text-align: center;
  padding: 3px;

  font-family: Helvetica Neue, sans-serif;
  font-size: 18px;
  color: hsl(0, 0%, 20%);
  :focus {
    outline: none;
  }

  ::placeholder {
    color: hsl(0, 0%, 50%);
  }

  @media (max-width: 688px) {
    width: 100%;
    margin: 3px 0;
    height: 26px;
  }
`
export const Button = styled.button`
  background-color: ${colors.BLACK};
  color: ${colors.WHITE};
  font-family: ${styling.NORMAL};
  font-size: ${sizing.SMALLER}px;
  border-radius: 15px;
  border: none;
  width: 10%;
  cursor: pointer;
  height: 38px;

  @media (max-width: 688px) {
    width: 100%;
    margin: 3px 0 0;
    height: 26px;
  }
  svg {
    margin-left: 10px;
  }
`
