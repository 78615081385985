import { React } from 'react'
import {
  Wrapper,
  Container,
  CardInfos,
  InfoTitle,
  CardInfo,
  MenuInfo,
  ItemMenu,
  CardCopy,
  ContainerLogo,
} from './style'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import LogoGuia from '../../Assets/Images/logo-branco.png'

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <ContainerLogo>
          <img src={LogoGuia} alt="AppFooter" />
        </ContainerLogo>

        <InfoTitle>Fale conosco</InfoTitle>
        <CardInfos>
          <CardInfo>
            <MenuInfo>
              <ItemMenu href="mailto:contato@guiadaconstrucao1.com.br">
                <FontAwesomeIcon width={64} icon={faEnvelope} />
                contato@guiadaconstrucao1.com.br
              </ItemMenu>
              <ItemMenu href="https://wa.me/5519992050538&text=Olá, estava navegando no site Guia da Construção e gostaria de mais informações">
                <FontAwesomeIcon width={64} icon={faWhatsapp} />
                (19) 99205 0538
              </ItemMenu>
              <ItemMenu href="https://www.facebook.com/guiadaconstrucaooficial/">
                <FontAwesomeIcon width={64} icon={faFacebook} />
                @guiadaconstrucaooficial
              </ItemMenu>
              <ItemMenu href="https://www.instagram.com/guiadaconstrucaooficial/">
                <FontAwesomeIcon width={64} icon={faInstagram} />
                @guiadaconstrucaooficial
              </ItemMenu>
            </MenuInfo>
          </CardInfo>
          <CardInfo>
            <MenuInfo>
              <ItemMenu href="/quemsomos">
                <FontAwesomeIcon width={64} icon={faChevronRight} />
                Institucional
              </ItemMenu>
              <ItemMenu href="/comotrabalhamos">
                <FontAwesomeIcon width={64} icon={faChevronRight} />
                Nossos Serviços
              </ItemMenu>
              <ItemMenu href="/politica-de-privacidade">
                <FontAwesomeIcon width={64} icon={faChevronRight} />
                Política de Privacidade
              </ItemMenu>
            </MenuInfo>
          </CardInfo>
          <CardInfo>
            <MenuInfo>
              <ItemMenu href="/orcamentos">
                <FontAwesomeIcon width={64} icon={faChevronRight} />
                Orçamentos
              </ItemMenu>
              <ItemMenu href="/login">
                <FontAwesomeIcon width={64} icon={faChevronRight} />
                Relatório de Obra
              </ItemMenu>
              <ItemMenu href="/artigos">
                <FontAwesomeIcon width={64} icon={faChevronRight} />
                Dicas para sua Obra
              </ItemMenu>
              <ItemMenu href="/Cadastro">
                <FontAwesomeIcon width={64} icon={faChevronRight} />
                Quero Anunciar
              </ItemMenu>
            </MenuInfo>
          </CardInfo>
        </CardInfos>
        <CardCopy>
          <strong>© 2021 | Guia da Construção.</strong>
          <span>Todos os direitos reservados</span>
          <span>Desenvolvido por BestPro</span>
        </CardCopy>
      </Container>
    </Wrapper>
  )
}

export default Footer
