import styled from 'styled-components'
import colors from '../../Theme/colors'
import { styling, sizing } from '../../Theme/fonts';


export const Wrapper = styled.div`
    margin: 0 auto;
    max-width: 1050px;
    background-color: #fff;
    height: auto;
    font-family: ${styling.NORMAL};

`;

export const ContainerHeader = styled.div`
`;

export const HeaderBody = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    @media(max-width: 688px) {
        flex-direction: column;
        justify-content: center;
        
        a {text-align: center;}
    }
`;

export const Img = styled.img`
    width: 144px;
    margin-left: 36px;
    transition: transform .2s;
    :hover{
        transform: scale(1.0);
    }
`;

export const Menu = styled.ul`
    width: 45%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    transition: transform .2s;
    font-size: ${sizing.SMALL}px;

    a {
        text-decoration: none;
        color: ${colors.BLACK};
    }
    i {
        background-color: grey;
        border: 1px solid grey;
        border-radius: 50%;

    }
    
    a:hover{
        background-color: rgba(250, 251, 252, 0.2);
        color: ${colors.YELLOW_GUIA};
        border-top: 3px solid ${colors.YELLOW_GUIA};
        transform: scale(1.1);
    }
    
    @media(max-width: 688px) {
        width: 100%;
        margin: 0;
        padding: 0 0 10px 0;

        a:nth-child(5) label{
            display: none;
        }
    }
`;

export const HeaderFooter = styled.div`
    width: 100%;
    background-color: ${colors.YELLOW_GUIA};
    
    display: flex;
    justify-content: flex-end;
    a{
        font-size: ${sizing.SMALL}px;
        text-decoration: none;
        color: ${colors.WHITE};
        padding: 10px 30px;
        transition: transform .2s;
    }
    a:hover{
        background-color: rgba(250, 251, 252, 0.2);
        transform: scale(1.2);
    }
    
    @media(max-width: 688px) {
        align-items: center;
        text-align: center;

        a{
            padding: 5px;
            width: 33%;
        }
    }
`;