import React, { Fragment, useState, useEffect, useRef, useNavigate } from 'react'
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import colors from '../../Theme/colors'
import { GetAssociados } from '../../Service/ApiService'

import FormSearch from '../../Components/FormPesquisa'
import CardEmpresa from '../../Components/CardHome/CardEmpresa/CardEmpresa'
import CardInline from './CardInline/index'
import { CardBox } from '../Home/styles'
import Modal from '../../Components/ModalHome/index'
import {
  Wrapper,
  Container,
  CardList,
  CardBoxInline,
  ItensSearch,
  Itens,
  Item,
  Label,
  Button,
  ViewList,
  IconSpinner,
} from './style'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSpinner, faThLarge, faTimes } from '@fortawesome/free-solid-svg-icons'
import { scryRenderedComponentsWithType } from 'react-dom/test-utils'
import Loader from '../../Components/Loader'
import useLoader from '../../Reducers/useLoader'

const ListLojas = (props) => {
  const dispatch = useDispatch()
  const [viewMode, setViewMode] = useState(false)

  const [anuncios, setAnuncios] = useState([])
  const [listAll, setListAll] = useState()
  const [page, setPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [loading, setLoading] = useState(false)

  const [filtros, setFiltros] = useState({ produto: '', nmCategoria: '', estado: '', cidade: '', filtro: '' })
  const tokenApp = useSelector((state) => state.TokenApp)
  const filtro_Busca = useSelector((state) => state.Filtro_Busca)
  const params = new URLSearchParams(props.location.search)
  const navigate = useHistory()

  const loadMore = useRef(null)
  var screenSize = window.screen.width
  useEffect(() => {
    setViewMode(screenSize < 688 ? true : false)
  }, [screenSize])

  async function loadData(pageNumber = page, shouldRefresh = false, busca = filtros) {
    showLoader()
    var _listAll = []
    if (loading) return
    setLoading(false)

    if (listAll === undefined || shouldRefresh) {
      _listAll = await GetAssociados(tokenApp, busca.produto, busca.filtro, busca.estado, busca.cidade)
      setListAll(_listAll)
      if (_listAll.length === 0) setAnuncios([])
    } else {
      _listAll = listAll
    }

    if (pageNumber >= _listAll.length) return
    setLoading(true)

    let data = []
    var nrItem = pageNumber
    for (let index = 0; index < 10; index++) {
      data.push(_listAll[nrItem])
      nrItem++
      if (nrItem >= _listAll.length) break
    }

    setPage(nrItem)
    setAnuncios(shouldRefresh ? data : [...anuncios, ...data])
    setLoading(false)
    hideLoader()
  }

  useEffect(() => {
    if (filtro_Busca) {
      setFiltros(filtro_Busca)
      dispatch({ type: 'Filtro_Busca', value: undefined })
    }
  }, [filtro_Busca])

  useEffect(() => {
    loadData(0, true, filtros, filtros)
  }, [filtros])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0]
      if (target.isIntersecting) {
        setCurrentPage((old) => old + 1)
      }
    })

    if (loadMore.current) {
      observer.observe(loadMore.current)
    }
  }, [loading])

  useEffect(() => {
    if (page > 0) loadData()
  }, [currentPage])

  const [showModal, setShowModal] = useState(false)
  const [infosModal, setInfosModal] = useState({
    nome: '',
    nmCat: '',
    descricao: '',
    telefone: '',
    urlLogo: '',
    urlSite: '',
    endereco: '',
  })

  const openModal = (loja) => {
    setInfosModal(loja)
    setShowModal(!showModal)
  }

  const openSingleLoja = (loja) => {
    navigate.push(`/associado/${string_to_slug(loja.nome)}`, loja, '_blank');
    // window.open(`/associado/${string_to_slug(loja.nome)}`, '_blank');
  }

  const string_to_slug = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaaeeeeiiiioooouuuunc------";

    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
  }

  const onSearchRemove = (name) => {
    if (name === 'nmCategoria') {
      setFiltros({ ...filtros, produto: '' })
    } else if (name === 'estado') {
      setFiltros({ ...filtros, estado: '' })
    } else if (name === 'cidade') {
      setFiltros({ ...filtros, cidade: '' })
    } else if (name === 'filtro') {
      setFiltros({ ...filtros, filtro: '' })
    }
  }

  useEffect(() => {
    async function loadModal() {
      var result = await GetAssociados(tokenApp, undefined, undefined, '', '', params.get('f'))

      if (result.length > 0) {
        setInfosModal(result[0])
        setShowModal(!showModal)
      }
    }

    if (params.get('f')) loadModal()
  }, [])

  const [loader, showLoader, hideLoader] = useLoader()

  return (
    <Fragment>
      <FormSearch value={filtros} onSearch={(filter) => setFiltros(filter)} />
      <Wrapper>
        <Container>
          {filtros !== undefined ? (
            <ItensSearch>
              <Itens>
                {filtros.produto ? (
                  <Item>
                    <Label>{filtros.nmCategoria}</Label>
                    <Button
                      onClick={() => {
                        onSearchRemove('nmCategoria')
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </Item>
                ) : (
                  <Fragment />
                )}
                {filtros.estado ? (
                  <Item>
                    <Label>{filtros.estado}</Label>
                    <Button
                      onClick={() => {
                        onSearchRemove('estado')
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </Item>
                ) : (
                  <Fragment />
                )}
                {filtros.cidade ? (
                  <Item>
                    <Label>{filtros.cidade}</Label>
                    <Button
                      onClick={() => {
                        onSearchRemove('cidade')
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </Item>
                ) : (
                  <Fragment />
                )}
                {filtros.filtro ? (
                  <Item>
                    <Label>{filtros.filtro}</Label>
                    <Button
                      onClick={() => {
                        onSearchRemove('filtro')
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </Item>
                ) : (
                  <Fragment />
                )}
              </Itens>

              <ViewList>
                <div>Exibir como:</div>
                <FontAwesomeIcon
                  size="lg"
                  color={viewMode ? colors.COLOR_DEFAULT_BUTTON : 'black'}
                  icon={faThLarge}
                  onClick={() => setViewMode(true)}
                />
                <FontAwesomeIcon
                  size="lg"
                  color={!viewMode ? colors.COLOR_DEFAULT_BUTTON : 'black'}
                  icon={faBars}
                  onClick={() => setViewMode(false)}
                />
              </ViewList>
            </ItensSearch>
          ) : (
            <Fragment />
          )}

          <Modal showModal={showModal} setShowModal={setShowModal} infos={infosModal} />

          {anuncios.length > 0 && <div>Exibindo {anuncios.length} associados.</div>}

          <CardList>
            {
              {
                true: (
                  <CardBox>
                    {anuncios?.map((loja, index) => (
                      <CardEmpresa
                        key={index}
                        participanteId={loja.participanteId}
                        onClick={(e) => openSingleLoja(e)}
                      ></CardEmpresa>
                    ))}
                  </CardBox>
                ),
                false: (
                  <CardBoxInline>
                    {anuncios?.map((loja, index) => (
                      <CardInline
                        key={index}
                        participanteId={loja.participanteId}
                        onClick={(e) => openSingleLoja(e)}
                      ></CardInline>
                    ))}
                  </CardBoxInline>
                ),
              }[viewMode]
            }

            {anuncios.length ? (
              <Fragment>
                {listAll.length > page && (
                  <IconSpinner ref={loadMore}>
                    <FontAwesomeIcon icon={faSpinner} pulse fixedWidth size="4x" />
                  </IconSpinner>
                )}
              </Fragment>
            ) : (
              <h2 style={{ textAlign: 'center' }}>Nenhum registro encontrado</h2>
            )}
          </CardList>
        </Container>
      </Wrapper>
      {loader}
    </Fragment>
  )
}

export default ListLojas
