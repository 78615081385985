import React, { Fragment, useState, useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faShareSquare } from '@fortawesome/free-regular-svg-icons'
import { faGlobe, faMapMarkerAlt, faTimes, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faPinterest, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import colors from '../../Theme/colors'

import { GetAnuncios, AddFavorito } from '../../Service/ApiService'
import { toast, Bounce } from 'react-toastify'
import { useSelector } from 'react-redux'

import {
    Info,
    CardContato,
    Logo,
    Share,
    Title,
    Row,
    ContatoItem,
    Descricao,
    Categorias,
    CardCategoria,
    Imagens,
    FrameFacebook,
    Wrapper,
    Container,
    CardHeader,
    Close,
  } from '../../Components/ModalHome/style'

import GalleryImages from '../../Components/GalleryImages'
import MapaModal from '../../Components/Mapa'
import useLoader from '../../Reducers/useLoader'

const SingleLoja = (props) => {

    // console.log(props.location.state)

    const { nome, descricao, urlFacebook, urlLogo, urlSite, numWhatsApp, telefone } = [
        props.location.state.nome,
        props.location.state.descricao,
        props.location.state.urlFacebook,
        props.location.state.urlLogo,
        props.location.state.urlSite,
        props.location.state.numWhatsApp,
        props.location.state.telefone,
    ];
    const infos = {}
    const tokenType = useSelector((state) => state.TokenType)
    const tokenApp = useSelector((state) => state.TokenApp)
    const [favorite, setFavorite] = useState(false)
    const [anuncios, setAnuncios] = useState()
    const [share, setShare] = useState(false)
    const [loader, showLoader, hideLoader] = useLoader()

    useEffect(() => {
        async function loadImagens() {
          showLoader()
          console.log(tokenApp)
          var result = await GetAnuncios(tokenApp, props.location.state.participanteId)
          if (result.length === 0) {
            setAnuncios(undefined)
            hideLoader()
          } else {
            setAnuncios(result)
            hideLoader()
          }
        }
        if(!anuncios) loadImagens()
      }, [anuncios])

    async function onFavorito(ev) {
        if (tokenType !== 'Cli') {
          window.location.href = '/Login?f=' + props.location.state.participanteId
        } else {
          favorito()
        }
      }
    
      async function favorito() {
        var response = await AddFavorito(tokenApp, props.location.state.participanteId)
        if (response.result) {
          setFavorite(!favorite)
        } else {
          toast(response.message, {
            type: 'warning',
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: 'top-right',
          })
        }
      }

    const justNumbers = (text) => {
        var numbers = text.replace(/[^0-9]/g,'');
        return parseInt(numbers);
    }

    return (
    <Wrapper>
        <Container>
        <Row>
            <Info>
            <CardHeader>
                <Logo>
                <img src={props.location.state.urlLogo} alt={props.location.state.nome} />
                <Share>
                    <div>
                    <FontAwesomeIcon icon={faStar} color={favorite ? colors.YELLOW : ''} onClick={onFavorito} />
                    <FontAwesomeIcon icon={faShareSquare} onClick={() => setShare(!share)} />
                    </div>
                    {
                    {
                        true: (
                        <div className="btn">
                            <a
                            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                            target="_blank"
                            rel="noreferrer"
                            >
                            <FontAwesomeIcon width={64} icon={faFacebook} />
                            </a>
                            <a
                            href={`https://twitter.com/intent/tweet?text=${props.location.state.nome}&url=${window.location.href}&related=`}
                            target="_blank"
                            rel="noreferrer"
                            >
                            <FontAwesomeIcon width={64} icon={faTwitter} />
                            </a>
                            <a
                            href={`http://pinterest.com/pin/create/button/?url=${window.location.href}`}
                            target="_blank"
                            rel="noreferrer"
                            >
                            <FontAwesomeIcon width={64} icon={faPinterest} />
                            </a>
                        </div>
                        ),
                    }[share]
                    }
                </Share>
                </Logo>

                <CardContato>
                <Title>{props.location.state.nome}</Title>

                <ContatoItem>
                    {props.location.state.endereco !== ' - ' && (
                    <Fragment>
                        <FontAwesomeIcon width={64} icon={faMapMarkerAlt} />
                        <p>{props.location.state.enderecoComp}</p>
                    </Fragment>
                    )}
                </ContatoItem>
                <ContatoItem>
                    {props.location.state.urlSite !== '' && (
                    <Fragment>
                        <FontAwesomeIcon width={64} icon={faGlobe} />
                        <p>
                        <a href={`${props.location.state.urlSite}`} target="_blank" rel="noreferrer">
                            {props.location.state.urlSite}
                        </a>
                        </p>
                    </Fragment>
                    )}
                </ContatoItem>
                <ContatoItem>
                    {props.location.state.numWhatsApp !== '' && (
                    <Fragment>
                        <FontAwesomeIcon icon={faWhatsapp} />
                        <p>
                        <a href={`https://wa.me/55${justNumbers(props.location.state.numWhatsApp)}`} target="_blank" rel="noreferrer">
                            {props.location.state.numWhatsApp}
                        </a>
                        </p>
                    </Fragment>
                    )}
                </ContatoItem>
                <ContatoItem>
                    {props.location.state.telefone !== '' && (
                    <Fragment>
                        <FontAwesomeIcon icon={faPhoneAlt} />
                        <p>{props.location.state.telefone}</p>
                    </Fragment>
                    )}
                </ContatoItem>
                </CardContato>
            </CardHeader>

            <Descricao>{props.location.state.descricao}</Descricao>
            </Info>
            {props.location.state.endereco !== ' - ' && <MapaModal address={props.location.state.enderecoComp} />}
        </Row>

        <Row>
            {props.location.state.categorias !== undefined && (
            <Categorias>
                {props.location.state.categorias.map((item, i) => (
                <CardCategoria key={i}>
                    <input id={`InCat_${i}`} value={item.nome} readOnly />
                    <label id={`LbCat_${i}`}>{item.nome}</label>
                </CardCategoria>
                ))}
            </Categorias>
            )}
        </Row>

        <Row>
            {props.location.state.urlFacebook && (
            <FrameFacebook
                src={`https://www.facebook.com/plugins/page.php?href=${props.location.state.urlFacebook}&tabs=timeline&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`}
                className="iframeFacebook"
                frameBorder="0"
                allowfullscreen="true"
                scrolling="false"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></FrameFacebook>
            )}

            {anuncios && (
            <Imagens>
                <GalleryImages anuncios={anuncios} logoAnuncio={urlLogo}></GalleryImages>
            </Imagens>
            )}
        </Row>
        </Container>
    </Wrapper>
    );
}

export default SingleLoja;