import styled from 'styled-components'
import Slider from 'react-slick'

export const Wrapper = styled.div`
  width: calc(100%);
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const SliderBar = styled(Slider)`
  width: calc(100% - 50px);
`

export const Categoria = styled.div`
  min-height: 60px;
  min-width: 60px;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
  background-color: white;
  border-radius: 10px;

  margin: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`

export const CardImg = styled.div`
  display: flex;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  background: center no-repeat #f7931e;
  background-image: linear-gradient(45deg, rgba(229, 123, 36, 1) 0%, rgba(255, 171, 75, 1) 100%);
  border-radius: 100%;

  img {
    padding: 15px;
  }
`
